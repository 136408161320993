.CostOfOwnership {
       .text-center {
              p.h1 {
                     margin:50px 0px;
              }
       }
}

#ev-jumbotron-details {
       .show-full-list-container {
              button {
                     position: relative;
                     text-transform: uppercase;
                     padding:8px 40px 8px 26px;

                     &.active {
                            &:after {
                                   background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDI0LjMuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCAxMCA3IiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCAxMCA3OyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+Cgkuc3Qwe2ZpbGw6IzJDNjFBRTt9Cjwvc3R5bGU+CjxwYXRoIGNsYXNzPSJzdDAiIGQ9Ik05LjUsNS4zTDguMyw2LjVMNSwzTDEuNyw2LjVMMC41LDUuM0w1LDAuNUw5LjUsNS4zeiIvPgo8L3N2Zz4K")
                            }
                     }

                     &:after {
                            content:"";
                            width:20px;
                            height:20px;
                            background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTAiIGhlaWdodD0iNyIgdmlld0JveD0iMCAwIDEwIDciIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik0wLjUgMS43MzUyOUwxLjY2NjY3IDAuNUw1IDQuMDI5NDFMOC4zMzMzMyAwLjVMOS41IDEuNzM1MjlMNSA2LjVMMC41IDEuNzM1MjlaIiBmaWxsPSIjMkM2MUFFIi8+Cjwvc3ZnPgo=");
                            background-repeat: no-repeat;
                            background-size:12px auto;
                            display:block;
                            position: absolute;
                            top:16px;
                            right:10px;
                     }
              }
       }
}

.graph-assumptions {
       .legal-disclaimer {
              text-align: left;
       }
}

.monthly-fuel-cost {
       p.h3.graph-sub-title {
              font-size:18px;
              margin-bottom: 30px;
       }

       .show-full-list-container {
              position: relative;
              margin:35px 0 0;

              button {
                     position: relative;
                     text-transform: uppercase;
                     background-color:white;
                     padding:8px 40px 8px 26px;

                     &.active {
                            background-color:white;
                            color:#205694;
                            &:after {
                                   background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDI0LjMuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCAxMCA3IiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCAxMCA3OyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+Cgkuc3Qwe2ZpbGw6IzJDNjFBRTt9Cjwvc3R5bGU+CjxwYXRoIGNsYXNzPSJzdDAiIGQ9Ik05LjUsNS4zTDguMyw2LjVMNSwzTDEuNyw2LjVMMC41LDUuM0w1LDAuNUw5LjUsNS4zeiIvPgo8L3N2Zz4K")
                            }
                     }

                     &:after {
                            content:"";
                            width:20px;
                            height:20px;
                            background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTAiIGhlaWdodD0iNyIgdmlld0JveD0iMCAwIDEwIDciIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik0wLjUgMS43MzUyOUwxLjY2NjY3IDAuNUw1IDQuMDI5NDFMOC4zMzMzMyAwLjVMOS41IDEuNzM1MjlMNSA2LjVMMC41IDEuNzM1MjlaIiBmaWxsPSIjMkM2MUFFIi8+Cjwvc3ZnPgo=");
                            background-repeat: no-repeat;
                            background-size:12px auto;
                            display:block;
                            position: absolute;
                            top:16px;
                            right:10px;
                     }
              }
       }

       .faq-disclaimer {
              display:none;
       }
}

.cost-comparison {
       p.h3.graph-sub-title {
              font-size:18px;
              margin-bottom: 30px;
       }

       .show-full-list-container {
              position: relative;
              margin:35px 0 0;

              button {
                     position: relative;
                     text-transform: uppercase;
                     background-color:white;
                     padding:8px 40px 8px 26px;

                     &.active {
                            background-color:white;
                            color:#205694;
                            &:after {
                                   background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDI0LjMuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCAxMCA3IiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCAxMCA3OyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+Cgkuc3Qwe2ZpbGw6IzJDNjFBRTt9Cjwvc3R5bGU+CjxwYXRoIGNsYXNzPSJzdDAiIGQ9Ik05LjUsNS4zTDguMyw2LjVMNSwzTDEuNyw2LjVMMC41LDUuM0w1LDAuNUw5LjUsNS4zeiIvPgo8L3N2Zz4K")
                            }
                     }

                     &:after {
                            content:"";
                            width:20px;
                            height:20px;
                            background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTAiIGhlaWdodD0iNyIgdmlld0JveD0iMCAwIDEwIDciIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik0wLjUgMS43MzUyOUwxLjY2NjY3IDAuNUw1IDQuMDI5NDFMOC4zMzMzMyAwLjVMOS41IDEuNzM1MjlMNSA2LjVMMC41IDEuNzM1MjlaIiBmaWxsPSIjMkM2MUFFIi8+Cjwvc3ZnPgo=");
                            background-repeat: no-repeat;
                            background-size:12px auto;
                            display:block;
                            position: absolute;
                            top:16px;
                            right:10px;
                     }
              }
       }

       .faq-disclaimer {
              display:none;
       }
}


.fuel-range-chart {
       p.h3 {
              font-size:18px;
              margin-bottom: 30px;

              strong {
                     color:rgb(33, 135, 57);
              }
       }

       .show-full-list-container {
              position: relative;
              margin:35px 0 0;

              button {
                     position: relative;
                     text-transform: uppercase;
                     background-color:white;
                     padding:8px 40px 8px 26px;

                     &.active {
                            background-color:white;
                            color:#205694;
                            &:after {
                                   background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDI0LjMuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCAxMCA3IiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCAxMCA3OyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+Cgkuc3Qwe2ZpbGw6IzJDNjFBRTt9Cjwvc3R5bGU+CjxwYXRoIGNsYXNzPSJzdDAiIGQ9Ik05LjUsNS4zTDguMyw2LjVMNSwzTDEuNyw2LjVMMC41LDUuM0w1LDAuNUw5LjUsNS4zeiIvPgo8L3N2Zz4K")
                            }
                     }

                     &:after {
                            content:"";
                            width:20px;
                            height:20px;
                            background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTAiIGhlaWdodD0iNyIgdmlld0JveD0iMCAwIDEwIDciIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik0wLjUgMS43MzUyOUwxLjY2NjY3IDAuNUw1IDQuMDI5NDFMOC4zMzMzMyAwLjVMOS41IDEuNzM1MjlMNSA2LjVMMC41IDEuNzM1MjlaIiBmaWxsPSIjMkM2MUFFIi8+Cjwvc3ZnPgo=");
                            background-repeat: no-repeat;
                            background-size:12px auto;
                            display:block;
                            position: absolute;
                            top:16px;
                            right:10px;
                     }
              }
       }

       .faq-disclaimer {
              display:none;
       }
}


.show-sources {

       .show-full-list-container {
              position: relative;

              button {
                     position: relative;
                     text-transform: uppercase;
                     background-color:white;
                     padding:8px 40px 8px 26px;

                     &.active {
                            background-color:white;
                            color:#205694;
                            &:after {
                                   background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDI0LjMuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCAxMCA3IiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCAxMCA3OyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+Cgkuc3Qwe2ZpbGw6IzJDNjFBRTt9Cjwvc3R5bGU+CjxwYXRoIGNsYXNzPSJzdDAiIGQ9Ik05LjUsNS4zTDguMyw2LjVMNSwzTDEuNyw2LjVMMC41LDUuM0w1LDAuNUw5LjUsNS4zeiIvPgo8L3N2Zz4K")
                            }
                     }

                     &:after {
                            content:"";
                            width:20px;
                            height:20px;
                            background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTAiIGhlaWdodD0iNyIgdmlld0JveD0iMCAwIDEwIDciIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik0wLjUgMS43MzUyOUwxLjY2NjY3IDAuNUw1IDQuMDI5NDFMOC4zMzMzMyAwLjVMOS41IDEuNzM1MjlMNSA2LjVMMC41IDEuNzM1MjlaIiBmaWxsPSIjMkM2MUFFIi8+Cjwvc3ZnPgo=");
                            background-repeat: no-repeat;
                            background-size:12px auto;
                            display:block;
                            position: absolute;
                            top:16px;
                            right:10px;
                     }
              }

              .row {
                     margin-top:55px;
              }
       }


       .faq-disclaimer {
              display:none;
       }
}

.bottom-disclaimer {
       margin:35px 0 0;
       color:#666666;
}