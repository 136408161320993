

.num-chargers-found {
       p {
              font-size: 16px;
              margin-bottom: 26px;
              color:#000;
       }
}

.homecharger-filters {
       .input-well .btn {
              background-color:#fff;
              color:#0072CE;
       }
}