@import "src/client_customizations/scss/client_master.scss";
.Footer {
	background-color: $GenericFooter-background-color;
	padding-top: 20px;
	color: $GenericFooter-color;
	.disclaimer_container {
		font-family: Arial, Helvetica;
		display: flex;
		align-items: center;
		margin-left: '5px' ;
		font-size: 10px; /* Set the font size to 10px */
		div {
			   background-color:transparent !important;
	
			   img {
					  max-width: 50%;
					  margin:0 0 0px;
			   }
	
			   p {
					  color:white;
			   }
		}
	}
	.h3 {
		margin-bottom: 5px;
		font-size: $GenericFooter-heading-font-size;
		color: $GenericFooter-heading-color;
	}
	a {
		transition: 0.3s all ease;
		-webkit-transition: 0.3s all ease;
		font-size: $GenericFooter-links-font-size;
		line-height: $GenericFooter-links-line-height;
		color: $GenericFooter-links-color;
		// &:hover {
		// 	color: $GenericFooter-links-hover-color;
		// 	padding-left: 5px;
		// 	text-decoration: underline;
		// }
	}
	.p {
		color: $GenericFooter-links-color;
	}
	.social-media-row {
		text-align: center;
	}
	img {
		height: 48px;
	}
	.footer-border {
		border-right: 1px solid #fff;
	}
	.zr-row {
		justify-content: center;

		a {
			padding-top: 4px;
			padding-bottom: 4px;
		}
	}

	.dte-row {
		justify-content: center;
	}

	.pad-right {
		padding-right: 8px;
	}

	.pad-left {
		padding-left:8px;
	}
	.p {
		color: $GenericFooter-links-color;
	}
}

@media screen and (max-width: 992px) {
	.Footer {
		.footer-border {
			border-right: 0px solid #73767B;
		}
		.zr-row{
			flex-direction: column;
			align-items: center;
		}
		.pad-right {
			padding-right: 0px;
		}
		.pad-left {
			padding-left:0px;
		}
	}
}
