@import "src/client_customizations/scss/client_master.scss";

.ChargingMap {
	h2 {
		margin-bottom: 25px;
	}

	.MapControlPanel {
		.map-controls {
			display: flex;
			justify-content: center;

			.form-group {
				width: 350px;

				.input-group-prepend {
					.input-group-text {
						background-color:transparent;
						border:none;

						img {
							width:20px !important;
						}
					}
				}

				&.search {
					margin-left:15px;
					width:140px;

					.btn-ae {
						padding:8px 0;
					}
				}
			}
		}
	}

	.form-inline {
		.input-group-prepend {
			margin-right:10px;

			.input-group-text {
				background-color:transparent;
				border:none;

				img {
					width:20px !important;
				}
			}
		}

		.input-group {
			padding-right:0 !important;
		}

		#input-zipcode-for-charging-map {
			border-radius: 0;
			min-width: 300px;

			@media(max-width: 430px) {
				min-width: 0px;
			}
		}
	}

	.spinner-container {
		margin: 0 auto;
		text-align: center;
	}
	.map-container {
		position: relative;
		height: 552px;
		width: 100%;
		border: $ChargingMap-map-container-border;
		border-radius: 4px;
		margin-top:20px;
	}
	.sidebar-container {
		position: absolute;
		left: 5px;
		top: 5px;
		z-index: 1;
	}
	.legend {
		background: $ChargingMap-legend-background;
		// border: $ChargingMap-legend-border;
		border:none;
		border-radius: $ChargingMap-legend-border-radius;
		font-size: $ChargingMap-legend-font-size;
		position: absolute;
		top: 84px;
		z-index: 9999;
		width:200px;
		box-shadow: 3px 3px 10px rgba(0, 0, 0, .2);

		@media(max-width:768px) {
			position: relative;
			top:0;
			width:100%;
		}


		.charger-type {
			position: relative;
			padding-right: 2rem;
			margin-bottom: 1rem;
			&:last-child {
				margin-bottom: 0;
			}
			img {
				width: 25px;
				position: absolute;
				right: 0;
				top: 0;
			}
		}
	}
	.SelectedChargingStationCard {
		background: $ChargingMap-legend-background;
		// border: $ChargingMap-legend-border;
		border:none;
		border-radius: $ChargingMap-legend-border-radius;
		font-size: $ChargingMap-legend-font-size;
		position: absolute;
		top: 300px;
		z-index: 9999;
		width:200px;
		box-shadow: 3px 3px 10px rgba(0, 0, 0, .2);

		@media(max-width:768px) {
			position: relative;
			top:0;
			width:100%;
		}
	}
	.show-full-list-container {
		margin: 75px 0 25px 0;
		text-align: center;

		.btn {
			color:#fff;
			font-weight: bold;
			background-color:$ChargingMap-showall-button-color;
			border:none;
			border-radius: 5px;
			text-transform: uppercase;
		}
	}
}
.DealerMap {
	h2 {
		text-align: center;
		margin-bottom: 50px;
	}
	.spinner-container {
		margin: 0 auto;
		text-align: center;
	}
	.map-container {
		position: relative;
		height: 552px;
		width: 100%;
		border: $ChargingMap-dealer-map-container-border;
		border-radius: $ChargingMap-dealer-map-container-border-radius;
	}
	.sidebar-container {
		position: absolute;
		left: 5px;
		top: 5px;
		z-index: 1;
	}
	.legend {
		background: $ChargingMap-dealer-legend-background;
		border: $ChargingMap-dealer-legend-border;
		border-radius: $ChargingMap-dealer-legend-border-radius;
		padding: 15px;
		font-size: 0.75rem;
		max-width: 199px;
		margin-bottom: 10px;
		position: relative;
		padding-right: 2rem;
		margin-bottom: 1rem;
		.charger-type {
			&:last-child {
				margin-bottom: 0;
			}
			img {
				width: 25px;
				position: absolute;
				right: 0;
				top: 0;
			}
		}
	}

	.dealerMap-content {
		background-color:#fff;
		padding:15px 0;

		.controls {
			.form-group {

				label {
					font-weight: bold;
					text-transform: uppercase;
					color:#2B2B2B;
				}

				.form-group {
					margin:0;
				}
			}

			.ZipcodeInputWithButton {
				#input-zipcode-for-charging-map {
					border-right:none;
				}

				.input-group {
					.input-group-append {
						.input-group-text {
							background-color:#fff !important;
							border-left:none;
						}
					}
				}
			}
		}
	}

	.SelectedDealerCard {
		a {
			color:#000;
		}
	}
	
	.show-full-list-container {
		margin: 40px 0 25px 0;
		text-align: center;
	}
}
.Map {
	overflow: hidden;
}
.locationCardHolder {
	margin-top: 20px;
}
