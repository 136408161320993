@import "src/client_customizations/scss/client_master.scss";

/* Apply yellow span underline for mobile views */
.Header {
	background-color: $Header-background-color;
	border-bottom: $Header-border-bottom;

	.language-toggle {
		position: absolute;
		top:0;
		right:0;

		@media(max-width:767px) {
			display:none;
		}

		select.form-control {
			background-color: #03559B;
			color:#fff;
			font-weight: bold;
			font-size: 12px;
			border: none;
			border-radius: 0px;
		}
	}

	a {
		color:$Header-link-color;
		text-transform: uppercase;
	}
	.navbar {
		padding-top: 0;
		padding-bottom: 0;
		.navbar-brand {
			padding-top: 0;
			padding-bottom: 0;
			img {
				width: 160px;
			}
		}
		.navbar-collapse {
			a.active {
				span {
					color: $Header-navbar-link-color;
					text-decoration: none;
				}
			}

      .navbar-nav {
        align-items: center;
      }

      .dropdown-item {
        border-radius: 0;
      }
		}

    .dropdown-menu {
      top: 50px;
      border-radius: 3px;

      .dropdown-item {
        font-size: 13px;


        &.active,
        &:active {
          background-color: transparent;
          color: $Header-navbar-link-color;
        }
      }
    }
	}
	.nav-item {
		position: relative;
		margin: 5px;

		&.parent-nav-item {
			margin:5px;

			@media(max-width:991px) {
				margin:0 5px;
			}

			.sub-nav {
				li.nav-item:last-child {
					margin:0;
				}
			}
		}

		a.parent-nav {
			position: relative;
			
			&:after {
				content:"";
				width:9px;
				height:7px;
				// background-color:red;
				position: absolute;
				top:15px;
				right:-3px;
				background-repeat: no-repeat;
				background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iOSIgaGVpZ2h0PSI2IiB2aWV3Qm94PSIwIDAgOSA2IiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgo8cGF0aCBkPSJNMCAxLjIzNTI5TDEuMTY2NjcgMEw0LjUgMy41Mjk0MUw3LjgzMzMzIDBMOSAxLjIzNTI5TDQuNSA2TDAgMS4yMzUyOVoiIGZpbGw9IiNFMzAwMUMiLz4KPC9zdmc+Cg==")
			}

			@media(max-width:991px) {
				display:none;
			}
		}

		&:hover {
			.sub-nav {
				display:block;
			}
		}
	}

	.sub-nav {
		width:155px;
		display:none;
		position: absolute;
		list-style-type: none;
		padding:0;
		left:0px;
		box-shadow: 0 3px 7px -2px rgba(2,63,32,.3);
		z-index:9;

		@media(max-width:991px) {
			display:block;
			position: relative;
			box-shadow: none;
			text-align: center;
		}

		li {
			background-color:#fff;
			margin:0;

			@media(max-width:991px) {
				margin:0 0 5px;
			}

			a {
				text-transform: none;
				padding:10px !important;
				display: block;
				white-space: nowrap;
				font-weight: 200 !important;
				border: 1px solid #fff;
				border-radius: 0px !important;
				transition: all .2s ease-out;
				line-height: 1.7em;

				@media(max-width:991px) {
					padding:0px !important;
					text-transform: uppercase;
					display:block !important;
					font-weight: 400 !important;
					border:none;
				}

				&.active {
					font-weight: 400 !important;
				}

				&:hover {
					@media(min-width:991px) {
						background: #F4F6F7;
						border: 1px solid #DFE5E8;
						color:#E3001C;
						// font-weight: 400 !important;
					}
				}
			}
		}
	}
}
.skip-to-content-link {
	position: absolute;
	top: -1000px;
	left: -1000px;
	height: 1px;
	width: 1px;
	text-align: left;
	overflow: hidden;
}
a.skip-to-content-link {
	&:active {
		left: 75px;
		top: 20px;
		width: auto;
		height: auto;
		overflow: visible;
	}
	&:focus {
		left: 75px;
		top: 20px;
		width: auto;
		height: auto;
		overflow: visible;
	}
	&:hover {
		left: 75px;
		top: 20px;
		width: auto;
		height: auto;
		overflow: visible;
	}
}
.navbar-toggler {
	border-color: $Header-navbar-toggler-border-color;
	color:$Header-navbar-toggler-color;
	.navbar-toggler-icon {
		background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(241,92,42, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
	}
}
@media (min-width: 992px) {
	.Header {
		.navbar {
			padding: 0;
			.navbar-collapse {
				a {
					padding: 10px;
					border-radius: 4px;
					font-weight: 700;
					letter-spacing: 0.6px;
					text-decoration: none;
				}
				a.active {
					font-weight: bold;
				}
			}
		}
	}
}
