@import "src/client_customizations/scss/client_master.scss";

.link-card-container {
	display: flex;
	flex-direction: row;
	// flex-wrap: nowrap;
	justify-content: center;
	align-items: stretch;
	max-width: 400px;
	margin: 0 auto;
}
.link-card {
	display: flex;
	text-align: center;
	flex-shrink: 1;
	flex-basis: 80px;
	cursor: pointer;
	img {
		width: 60px;
	}
	p {
		margin-top: 0.5rem;
		margin-bottom: 0;
	}
	&:hover {
		p {
			text-decoration: none;
			color: $LinkCard-card-hover-body-color;
		}
	}
	.evc-card {
		background-color: #F6F4F4;
		// border: 1px solid #f2f2f2;
		// box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.25);
		// padding: 0.5rem;
		// border-radius: 4px;
		p {
			font-size: 0.7rem;
		}
		&:hover {
			box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.25);
			background-color: #fff;
		}
	}
	.inside-link-card {
		&:hover {
			text-decoration:none;
		}
	}
}
@media (min-width: 576px) {
	.link-card-container {
		max-width: 750px;
	}
	.link-card {
		flex-basis: 120px;
		img {
			width: 100px;
		}
		.evc-card {
			p {
				font-size: 0.85rem;
			}
			padding: 1rem;
		}
	}
}
@media (min-width: 768px) {
	.link-card {
		flex-basis: 150px;
	}
}
