

#ev-jumbotron-title {
       h1 {
              font-size:32px;
       }
}

.btn-white {
       background-color:#fff;
       color:#205694;
       border:1px solid #DBDBDB;
}