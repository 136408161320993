@import "src/client_customizations/scss/client_master.scss";

.IncentiveCard {
	flex-basis: 49%;
	margin-right: 1%;
	margin-bottom: 3%;
	border-radius: 0px;
	border:none;
	box-shadow: 0 2px 25px 0 rgba(0, 0, 0, 0.25);
}
.IncentiveCardBody {
	padding: 1rem;
	text-align: center;
	color: $IncentiveCard-card-body-color;
	border-radius: 0;
	border-top:10px solid #00458A;

	h2 {
		text-align: center;
		font-size:18px;
		text-transform: uppercase;
		margin:0 0 25px 0;
	}

	.card-title {
		margin-top: 1rem;
		font-size:30px;
		color: $IncentiveCard-card-title-color;
	}
	img {
		margin-top: -12px;
		margin-bottom: -16px;
		width: 60px;
	}
}

.divider {
	padding:0 5%;

	hr {
		border-top:1px solid #ccc;
	}
}

.IncentiveCardBottom {
	padding: 1rem;
	text-align: left;
}

@media (min-width: 768px) {
	.IncentiveCard {
		flex-basis: 24%;
	}
	.IncentiveCardBody {
		padding: 1.5rem;
	}
	.IncentiveCardBottom {
		padding: 1.5rem;
	}
}
