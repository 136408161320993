@import "src/client_customizations/scss/client_master.scss";

section.container.homepage-jumbotron {
	display: flex;
}
.homepage-jumbotron {
	.big-promise {
		padding: $HomePage-big-promise-padding;
		color: $HomePage-big-promise-color;
		text-shadow: $HomePage-big-promise-text-shadow;
		min-height: $HomePage-big-promise-min-height;
		width: 100%;
	}
}
#link-card-buttons {
	margin-top: -7rem;
	padding: 3rem 1rem 1rem 1rem;
	background: transparent;
}
#why-choose-evs {
	padding: 0rem 1rem;
}
.big-promise-caption {
	top: 0;
	left: 0;
	position: absolute;
	padding: 1.5rem 1rem;
}

#homepage-cards-container {
	background-color: #F6F4F4;
	padding:0px;
}

.homepage-cards-container {
	margin:0px;
	max-height: 160px;

	.link-card-container {
		max-height: 160px;

		.link-card {
			.inside-link-card {
				height:190px;
				padding:40px 0;
				background-color:transparent;
				position: relative;
				top:-15px;
				border-radius: 0px;

				&:hover {
					background-color:#fff;
				}

				p.h3 {
					margin-top:15px;
				}
			}
		}
	}
}

#HomepageVehiclesCarousel {
	background-color: #fff;
	padding:4rem 4rem;
}

#HomepageIncentives {
	padding:4rem 4rem;
	background-color: #F6F4F4;

	.incentives-top {
		text-align: center;
		margin-bottom:25px;
	}
}

@media only screen and (max-device-width: 570px) {
	#homepage-cards-container{
		padding: 1rem;
	}
}