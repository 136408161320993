.ev-single-charging-map {
       padding:50px 0;

       .col-sm-12.text-center {
              display:flex;
              justify-content: space-between;

              @media(max-width:991px) {
                     display: block;
              }

              h2 {
                     font-size:25px;
              }
       }

       .map-container {
              margin-top:20px;
       }
}