@import "src/client_customizations/scss/client_master.scss";

.TabbedMaps {

  &.tabbed-maps-all-stations-tab {
    .tabbed-maps-panel, .tab-content {
      border:none;
      box-shadow: none;
      background-color: transparent;
    }
  }

  .tabbed-maps-panel {
    border-radius: 3px;
    border: 1px solid #dee2e6;
    box-shadow: 0 2px 5px 0px rgba(0,0,0, 0.15);
  }
  .tab-content {
    background-color: #ffffff;
    padding: 1rem 1.5rem;
    border-radius: 0 0 3px 3px;
  }
  .nav-tabs {
    border-radius: 3px 3px 0 0;
    background-color: #ffffff;
    padding: 0 1.5rem;
    display:none !important;

    .nav-item {
      margin-bottom: 0;
    }

    .nav-link {
      cursor: pointer;
      border-radius: 0;
      padding: 1.5rem 0;
      font-weight: bold;
      margin: 0 1rem;
      border: none;
    }

    .nav-item:first-child .nav-link {
      margin-left: 0;
    }

    .nav-link.active,
    .nav-item.show .nav-link,
    .nav-link:hover,
    .nav-link:focus {
      color: $red;
    }

  }
}
