@import "src/client_customizations/scss/client_master.scss";

.SelectedCard {
  padding: 1em;
  text-align: center;
  text-transform: uppercase;
  border: 1px solid #dbdbdb;
  border-radius: 4px;

  a {
    color:#000;
  }
}

.SelectedCard h5 {
  font-size: 1em;
}

.SelectedCard .address-container {
  color: #666666; 
  font-weight: 200;
}

.SelectedCard .oem-image {
  max-width: 150px;
  margin-bottom: 1.5em;
}
